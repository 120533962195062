const {
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {
  INVOCATION_ARMOR_OF_SHADOWS,
} = require('./../../invocationList')

const Armor_of_shadows_MTG_CLB_img = require('./../../../images/invocation/armor-of-shadows.webp')


module.exports = {
  [INVOCATION_ARMOR_OF_SHADOWS]: {
    src: Armor_of_shadows_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
}
