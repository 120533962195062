import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import invocationImageCollection from '@/constants/images/invocationImageCollection'

const InvocationImageList = ({ id, ...rest }) => (
  <ImageList
    imageList={invocationImageCollection[id]}
    {...rest}
  />
)

InvocationImageList.propTypes = {
  id: PropTypes.string.isRequired,
}

export default InvocationImageList
