import arrify from 'arrify'

import { featureCollection } from '@/constants/featureList'
import { pcSubClassCollection } from '@/constants/pcSubClassList'
import { spellCollection } from '@/constants/spellList'

const generateRequirementText = requirement => {
  if (requirement) {
    return arrify(Object.keys(requirement))
      .map(
        key => {
          switch (key) {
            case 'lvl':
              return `${requirement[key]}-й уровень колдуна`

            case 'spell': {
              const item = requirement[key]
              const { spellId, comment = '' } = typeof item === 'object'
                ? item
                : {spellId: item}
              const { name } = spellCollection[spellId]
              const commentText = comment
                ? ` ${comment}`
                : ''

              return `заклинание ${name}${commentText}`
            }

            case 'featureId': {
              const { name } = featureCollection[requirement[key]]

              return `умение ${name}`
            }

            case 'pcSubLassId': {
              const { name } = pcSubClassCollection[requirement[key]]

              return `покровитель ${name}`
            }

            default:
              return null
          }
        }
      )
      .filter(e => e)
      .join(', ')
  }

  return null
}

export default generateRequirementText
