import sortByText from '@/utils/sortByText'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import {featureCollection} from '@/constants/featureList'
import {pcSubClassCollection} from '@/constants/pcSubClassList'
import {
  invocationRequiredFeatureList,
  invocationRequiredPcSubLassIdList,
} from '@/constants/invocationList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

const lvlList = [
       2,  3,  4,  5,  6,  7,  8,  9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]

export default [
  {
    header: 'Требования',
    children: [
      {
        label: 'Требуемый уровень колдуна',
        type: FILTER_TYPE_SELECT,
        propName: 'requirementLvlMax',
        value: DEFAULT_FILTER_VALUE,
        filterHandler: value => item => {
          if (value && item.requirementLvl) {
            return value >= item.requirementLvl
          }

          return true
        },
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...lvlList.map(
            lvlNum  => ({
              value: lvlNum,
              text: lvlNum
            })
          )
        ],
      },
      {
        label: 'Договор колдуна',
        type: FILTER_TYPE_SELECT,
        propName: 'requirementFeatureId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...invocationRequiredFeatureList
            .map(
              featureId  => ({
                value: featureId,
                text: featureCollection[featureId].name,
              })
            )
            .sort(sortByText)
        ],
      },
      {
        label: 'Покровитель колдуна',
        type: FILTER_TYPE_SELECT,
        propName: 'requirementPcSubLassId',
        value: DEFAULT_FILTER_VALUE,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Любой'
          },
          ...invocationRequiredPcSubLassIdList
            .map(
              featureId  => ({
                value: featureId,
                text: pcSubClassCollection[featureId].name,
              })
            )
            .sort(sortByText)
        ],
      },
    ],
  },
  {
    header: 'Переиздания',
    children: [
      {
        label: 'Отменено?',
        title: 'Воззвание существовало только в Раскопанных Тайнах и позже было решено его не издавать?',
        type: FILTER_TYPE_SELECT,
        propName: 'isAbandoned',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Переиздано?',
        title: 'Воззвание было переиздано в другой официальной версии',
        type: FILTER_TYPE_SELECT,
        propName: 'isReReleased',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    label: 'Введите название воззвания или его текст',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
