import React from 'react'

import { invocationCollection } from '@/constants/invocationList'

import generateNameStr from '@/utils/generateNameStr'

import generateRequirementText from './utils/generateRequirementText'

import InvocationComponent from './InvocationComponent'

const InvocationContainer = ({ id }) => {
  if (id) {
    const invocation = invocationCollection[id]

    if (invocation) {
      const { requirement, ...rest } = invocation

      const requirementText = generateRequirementText(requirement)
      const {header, subHeader} = generateNameStr(invocation)

      return <InvocationComponent
        header={header}
        subHeader={subHeader}
        requirementText={requirementText}
        {...rest}
      />
    }
  }

  return null
}

export default InvocationContainer
