import React from 'react'

import { invocationCollection } from '@/constants/invocationList'

import AbandonedAt from '@/components/AbandonedAt'
import SourceInfo from '@/components/SourceInfo'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import Note from '@/components/Note'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'

import generateInvocationPageUrlById from '@/utils/generateInvocationPageUrlById'
import generateTextLinks from '@/utils/generateTextLinks'

import InvocationImageList from './components/InvocationImageList'

import './InvocationStyles.less'

const InvocationComponent = (
  {
    abandonedAt,
    description,
    header,
    id,
    note,
    releasedAs,
    releasedBeforeAsList,
    subHeader,
    source,
    requirementText,
  },
) => (
  <section className='Invocation'>
    <section className='Invocation_infoColumn'>
      <section className='Invocation_infoBlock'>
        <HeaderBlock
          className='Invocation_header'
          subHeader={subHeader}
        >
          {header}
        </HeaderBlock>
        <AbandonedAt
          className='Invocation_releaseInfo'
          preText='Это воззвание было отменено'
          abandonedAt={abandonedAt}
        />
        <ReleasedAs
          className='Invocation_releaseInfo'
          preText='Это воззвание было переиздано'
          releasedAs={releasedAs}
          collection={invocationCollection}
          urlGenerator={generateInvocationPageUrlById}
        />
        {
          requirementText
            ? (
              <div className='Invocation_requirement'>
                Требования: {requirementText}
              </div>
            )
            : null
        }
        <Note
          note={note}
          className='Invocation_note'
        />
        <Markdown className='Invocation_description'>
          {generateTextLinks(description)}
        </Markdown>
        <SourceInfo
          className='Invocation_source'
          source={source}
          useFullName
        />
      </section>
      <ReleasedBeforeAs
        preText='Это воззвание ранее издавалось'
        releasedAs={releasedBeforeAsList}
        collection={invocationCollection}
        urlGenerator={generateInvocationPageUrlById}
      />
    </section>
    <InvocationImageList id={id}/>
  </section>
)

export default InvocationComponent
