import React from 'react'

import Invocation from '@/components/Invocation'
import InvocationCatalog from '@/components/InvocationCatalog'

import {invocationCollection} from '@/constants/invocationList'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Таинственные воззвания',
  description: 'Каталог таинственных воззваний колдунов в Dungeons & Dragons',
}

const InvocationPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={invocationCollection}
    ItemComponent={Invocation}
    ListComponent={InvocationCatalog}
    {...props}
  />
)

export default InvocationPageTemplate
