import React from 'react'

import invocationList from '@/constants/invocationList'

import Catalog from '@/components/Catalog'

import generateInvocationPageUrlById from "@/utils/generateInvocationPageUrlById"

import filterListOriginal from './constants/filterList'

const InvocationCatalogContainer = () => {
  const itemList = invocationList.filter(({isReady}) => isReady)

  return (
    <Catalog
      filterListOriginal={filterListOriginal}
      itemList={itemList}
      itemNameList={itemList}
      pageTitle='Каталог таинственных воззваний'
      pageUrlGenerator={generateInvocationPageUrlById}
    />
  )
}

export default InvocationCatalogContainer
